import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes';
import {TaxonomyService} from '../services-http/taxonomy.service';
import {ITaxonomy} from '../types/taxonomy';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-taxonomy-input',
  templateUrl: './taxonomy-input.component.html',
  styleUrls: ['./taxonomy-input.component.scss']
})
export class TaxonomyInputComponent implements OnInit {
  @Output()

  public onClose: EventEmitter<ITaxonomy> = new EventEmitter();
  public categories: string[] = [];
  public taxonomy: Partial<ITaxonomy>;

  @ViewChild('catInput', {static: true}) catInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

  removeIcon = faTimesCircle;

  constructor(public taxService: TaxonomyService) { }

  ngOnInit() {
    this.taxonomy = {
      name: ''
    };
  }

  add(event: MatChipInputEvent) {
    const tag = (event.value || '').trim();
    const index = this.categories.indexOf(tag);
    if (index < 0) {
      this.categories.push(tag);
    }
    this.catInput.nativeElement.value = '';
  }

  remove(tag: string) {
    const index = this.categories.indexOf(tag);
    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    const splits = event.clipboardData.getData('Text').split(/;|,|\n/).map(v => v.trim()).filter((v, i) => v.length > 0 && splits.indexOf(v, i + 1) < 0);
    this.categories.push(...splits);
  }

  public async onSave() {
    this.taxonomy.list = { categories: this.categories };
    const oTac: ITaxonomy = await this.taxService.post(this.taxonomy as ITaxonomy);
    this.onClose.emit(oTac);
  }

}
