import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {SourceService} from '../services-http/source.service';
import {Observable, Subject} from 'rxjs';
import {ISourceFile} from '../types/source';
import {IModel} from '../types/model';
import {ILens} from '../types/lens';
import {ModelService} from '../services-http/models.service';
import {LensService} from '../services-http/lens.service';
import {ActivatedRoute, Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ITaxonomy} from '../types/taxonomy';
import {TaxonomyService} from '../services-http/taxonomy.service';
import {MODAL_CONFIG} from '../modal/modal.config';
import {UtilsService} from '../utils/utils.service';
import {filter, first, map, skip, take} from 'rxjs/operators';
import {GlobalStopWordService} from '../services-http/global-stop-word.service';
import {IStopWordsGlobal} from '../types/stop-words-global';

@Component({
  selector: 'app-create-lens',
  templateUrl: './create-lens.component.html',
  styleUrls: ['./create-lens.component.scss']
})
export class CreateLensComponent implements OnInit {
  @Input() sourceId: number;
  @Input() modalRef: NgbModalRef;
  @Input() allUsers: boolean = false;
  private childModalRef: NgbModalRef;
  public lenses: Observable<ILens[]>;
  public sources: Observable<ISourceFile[]>;

  public models: Observable<IModel[]>;
  public modelsCompleted: Observable<IModel[]>;
  public modelsPending: Observable<IModel[]>;
  public model: Observable<IModel>;

  public dto: Partial<ILens>;
  public dataDropDownText: string = 'Select Training Data';

  public dtoTaxonomy: ITaxonomy;
  public taxonomyDropDownPlaceholder: string = 'Select Optional Taxonomy';
  public taxonomyDropDownText: string = 'Select Optional Taxonomy';

  public stopWordLists: IStopWordsGlobal[] = [];
  public stopWordsDropDownPlaceholder = 'Add Stop Words List';
  public stopWordsDropDownText = 'Add Stop Words List';

  public profile: Keycloak.KeycloakProfile;
  public numberOfIterations: number[] = [500, 1000, 2500, 5000];
  public numberOfTopics: number[] = [15, 30, 45, 60];

  public sources$: Observable<ISourceFile[]>;
  public taxonomies$: Observable<ITaxonomy[]>;
  public submitHandler$: Subject<any> = new Subject<any>();

  public dupName: boolean = false;
  public lensNames: Observable<string[]>;
  private _cheatCount: number = 0;

  constructor(private sourceService: SourceService,
              private modelService: ModelService,
              private route: ActivatedRoute,
              private router: Router,
              public kc: KeycloakService,
              private modalService: NgbModal,
              private utilsService: UtilsService,
              public taxonomyService: TaxonomyService,
              public globalSW: GlobalStopWordService,
              public lenseService: LensService) {

    const awindow: any = window;
    awindow.quick = this.__quick.bind(this);
    console.log('init create-lens-component');
  }

  setSource(source: ISourceFile) {
    this.dto.source = source;
    this.dataDropDownText = source.originalname;
  }

  cheat(): void {
    this._cheatCount++;
    if (this._cheatCount > 9) {
      this.__quick();
    }
  }

  setTaxonomy(tax: ITaxonomy) {
    this.dtoTaxonomy = tax;
    this.dto.taxonomy = tax;
    this.taxonomyDropDownText = tax ? tax.name : this.taxonomyDropDownPlaceholder;
  }

  ngOnInit() {

    if (_.includes(this.router.url, '/platform/lenses/new')) {
      this.route.queryParams.subscribe(params => {
        const sId = params['source'];
        if (sId) {
          this.router.navigate(['/platform/lenses'], {queryParams: {new: true, source: sId}});
        } else {
          this.router.navigate(['/platform/lenses'], {queryParams: {new: true}});
        }
      });
    }

    this.sourceService.loadAll(this.allUsers);

    this.kc.loadUserProfile().then((aProfile => this.profile = aProfile));

    this.sources = this.sourceService.elements;
    this.lenses = this.lenseService.elements
      .pipe(
        first((lenses: ILens[]) => (lenses && lenses.length > 0))
      );

    this.models = this.modelService.elements;
    this.taxonomies$ = this.taxonomyService.elements;

    this.dto = {
      modifiers: [],
      threshold: 0.3, // Fixed for the forseable future
      numIteration: this.numberOfIterations[1],
      numberOfTopics: this.numberOfTopics[0],
      contentType: 1,
      description: 'default'
    };

    this.route.queryParams.subscribe(params => {

      this.taxonomyService.loadAll();
      this.taxonomies$.subscribe();

      this.globalSW.loadAll(true)
        .pipe(
          filter(list => list.length > 0),
          take(1)
        )
        .subscribe(globals => {
        const systemDefaults: IStopWordsGlobal[] = globals.filter(list => list.isDefault);
        this.stopWordLists = this.stopWordLists.concat(systemDefaults);
      });

      this.sources$ = this.sourceService.elements.pipe(
        filter( (sources: any[]) => sources && sources.length > 0),
        map((sources: any[]) => sources.filter(source => (source.validationState === 1 && !source.isSoftDeleted))),
        take(1)
      );

      this.sources$.subscribe(sources => {
        const sId = params['source'] || this.sourceId;
        if (sId) {
          const s = sources.find(d => d.id === +sId);
          if (s) {
            this.setSource(s);
          }
        }
        this.utilsService.fixFilesNamesTrainingData(sources);
      });
    });

    this.modelsCompleted = this.modelService.elements
      .pipe(map((x: IModel[]) => {
            return x.filter((model: IModel) => model.isCompleted);
          }));

    this.lenseService.loadAll(true);
    this.lensNames = this.lenseService.elements.pipe(
      filter(lenses => !!lenses),
      map((x: ILens[]) => {
        return x.map(val => val.name);
      })
    );

    this.modelsPending = this.modelService.elements.pipe(map((x: IModel[]) => {
      return x.filter((model: IModel) => !model.isCompleted && !model.isFailed);
    }));

    this.sourceService.loadAll(this.allUsers);
    this.modelService.loadAll(this.allUsers);
    this.globalSW.loadAll(this.allUsers);

    this.stopWordsDropDownText = 'Loading Stop-Word Lists...';
    this.globalSW.elements.pipe(skip(1), take(1)).subscribe(() => this.stopWordsDropDownText = this.stopWordsDropDownPlaceholder);

    // only allow 1 submission then close the window
    this.submitHandler$.pipe(
      take(1)
    ).subscribe(e => {
      this.dto.createdBy = this.profile.email;
      this.dto.nextGlobalStopwords = this.stopWordLists;
      this.lenseService.post(this.dto as ILens).subscribe((lense: ILens) => {
        this.modalRef.close(true);
        this.router.navigateByUrl('platform/lenses/train/' + lense.id);
      });
    });
  }

  onNameChange(event): void {
    if (event) {
      this.lensNames.pipe(take(1)).subscribe((names) => {
        names = names.map(val => val.toLowerCase());
        this.dupName = names.includes(event.target.value.toLocaleLowerCase());
      });
    }
  }

  setTopics(value: number) {
    this.dto.numberOfTopics = value;
  }

  setIterations(value: number) {
    this.dto.numIteration = value;
  }

  isOkToGo(): boolean {
    if (this.dto &&
      this.dto.source &&
      this.dto.name &&
      this.dto.description &&
      this.dto.contentType &&
      this.dto.numberOfTopics &&
      this.dto.numIteration &&
      !this.dupName) {
      return true;
    }
    return false;
  }

  onSubmit() {
    this.submitHandler$.next(true);
  }

  open(content, inputs?: {[input: string]: any}) {
    const options: NgbModalOptions = MODAL_CONFIG;
    options.windowClass = 'onUploadData';

    const modalRef = this.modalService.open(content, options);
    this.childModalRef = modalRef;
    if (inputs) { // note that inputs won't work if the modal content is a template
      Object.keys(inputs).forEach(input => modalRef.componentInstance[input] = inputs[input]);
    }
  }

  doTaxonomyComplete(taxonomy: ITaxonomy) {
    this.setTaxonomy(taxonomy);
    this.childModalRef.close();
    this.taxonomyService.loadAll();
  }

  doStopWordsComplete(stopWords?: IStopWordsGlobal) {
    if (stopWords) {
      this.stopWordLists.push(stopWords);
    }
    this.childModalRef.close();
    this.globalSW.loadAll();
  }

  isStopWordsSelected(dto: IStopWordsGlobal) {
    return !!this.stopWordLists.find(d => d.id === dto.id);
  }

  removeStopWords(sw: IStopWordsGlobal, $event: MouseEvent) {
    this.stopWordLists.splice(this.stopWordLists.indexOf(sw), 1);
    $event.stopPropagation();
  }

  private __quick(): void {
    this.numberOfIterations = [100, 200, 400, 600];
    this.numberOfTopics = [5, 15, 30,  60];
    this.setIterations(100);
    this.setTopics(5);
    this.dto.name = 'Quick ' + parseInt((Math.random() * 100000).toString());
  }
}
