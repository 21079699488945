import {IViewBy} from '../shared/top-filter/iviewby.interface';

export const LENS_SELECT_FILTER_OPTIONS = function() {
  return <IViewBy[]>[
    {
      key: 'createdAt',
      label: 'Most Recent',
      order: 'desc',
      selectedByDefault: true,
      isFilter: false
    },
    {
      key: 'stage',
      label: 'Stage: Low to High',
      order: 'asc',
      isFilter: false
    },
    {
      key: 'stage',
      label: 'Stage: High to Low',
      order: 'desc',
      isFilter: false
    },
    {
      key: 'isFinished',
      label: 'Finished Model',
      order: 'desc',
      isFilter: false
    },
    {
      key: 'separator',
      label: '──────────',
      order: 'desc',
      disabled: true,
      isFilter: false
    },
    {
      key: 'isSoftDeleted',
      label: 'Hidden',
      order: 'desc',
      isFilter: true
    }
  ];
};
