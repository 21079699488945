<ng-template ngbModalContainer></ng-template>
<div class="row topnav-secondary" >
  <div class="col-12 ps-3 pe-4">
    <div class="d-flex justify-content-between">
      <div class="">
        <button class="btn btn-primary" (click)="open(uploadModal)">
          Create Data Set Analysis
        </button>
      </div>
      <div class="">
        <app-top-filter
          [hasTextFilter]="true"
          [hasOrderFilter]="true"
          [selectOptions]="selectUAOptions">
        </app-top-filter>
      </div>
    </div>
  </div>
</div>

<cdk-table class="gridList" [dataSource]="units$" [ngClass]="{'datatable-load-blocker': unitService.isPayloadLoading}">
  <ng-container cdkColumnDef="name">
    <cdk-header-cell *cdkHeaderCellDef>Analysis Name</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.givenName}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="data">
    <cdk-header-cell *cdkHeaderCellDef>Data Set</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.documentCollection.name}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="model">
    <cdk-header-cell *cdkHeaderCellDef>Model</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.lens?.name}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="createdBy">
    <cdk-header-cell *cdkHeaderCellDef>Created By</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.createdBy}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="createdAt">
    <cdk-header-cell *cdkHeaderCellDef>Created At</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.createdAt | date:'medium'}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="next">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">
      <span class="text-left">
        <div *ngIf="!row.isCompleted">
          <div class="la-ball-beat ball-beat-color" *ngIf="!row.isFailed">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div *ngIf="row.isFailed">
            <span class="failed-data text-center action-link">Failed</span>
          </div>
        </div>
        <a title="View Analysis" class="action-link" *ngIf="row.isCompleted && !row.isFailed" routerLink="analysis/{{row.id}}">
          Explore Data Set
        </a>
      </span>
    </cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="download">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">
      <div *ngIf="row.isCompleted" class="btn downloadBtn" (click)="toFile(row)" title="Download">
        <fa-icon *ngIf="loaderMap.get(row.id)" [icon]="['fal', 'circle-notch']" [spin]="true"></fa-icon>
        <svg *ngIf="!loaderMap.get(row.id)" width="17px" height="21px" viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Training-Data" transform="translate(-1393.000000, -194.000000)" fill="#274A9F">
              <g id="training-data-list" transform="translate(38.000000, 124.000000)">
                <g id="download" transform="translate(1355.000000, 70.000000)">
                  <g id="download-icon">
                    <g>
                      <path
                        d="M15.857,19.15 C16.1607566,19.15 16.407,19.3962434 16.407,19.7 C16.407,20.0037566 16.1607566,20.25 15.857,20.25 L0.801,20.25 C0.497243388,20.25 0.251,20.0037566 0.251,19.7 C0.251,19.3962434 0.497243388,19.15 0.801,19.15 L15.857,19.15 Z"
                        id="Path_95-2" fill-rule="nonzero"></path>
                      <path
                        d="M3.06109169,10.4647648 C3.20729217,10.4654528 3.34720254,10.5243223 3.4487767,10.6272233 L8.38196541,15.560412 L13.3152233,10.6252233 C13.530491,10.4099556 13.879509,10.4099556 14.0947767,10.6252233 C14.3100444,10.840491 14.3100444,11.189509 14.0947767,11.4047767 L8.7725,16.7270504 C8.66922837,16.8311724 8.52865065,16.8897395 8.382,16.8897395 C8.23534935,16.8897395 8.09477163,16.8311724 7.9922067,16.7277601 L2.67094958,11.4055 C2.56682762,11.3022284 2.5082605,11.1616506 2.5082605,11.015 C2.5082605,10.8683969 2.56678964,10.7278628 2.67084829,10.6246005 C2.77452438,10.5215894 2.91493878,10.464077 3.06109169,10.4647648 Z"
                        id="Path_99" fill-rule="nonzero"></path>
                      <path
                        d="M7.779,0.729 C7.779,0.425243388 8.02524339,0.179 8.329,0.179 C8.63275661,0.179 8.879,0.425243388 8.879,0.729 L8.879,15.785 C8.879,16.0887566 8.63275661,16.335 8.329,16.335 C8.02524339,16.335 7.779,16.0887566 7.779,15.785 L7.779,0.729 Z"
                        id="Path_95" fill-rule="nonzero"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="visibility">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">
      <app-eye-toggle (click)="onHide(row)" [isEyeOpen]="row.isSoftDeleted" [eyeOpenTitle]="'Hide From List'" [eyeClosedTitle]="'Restore To List'"></app-eye-toggle>
    </cdk-cell>
  </ng-container>

  <cdk-header-row *cdkHeaderRowDef="['name', 'data', 'model', 'createdBy', 'createdAt', 'next', 'visibility']"></cdk-header-row>
  <cdk-row *cdkRowDef="let lens; columns: ['name', 'data', 'model', 'createdBy', 'createdAt', 'next', 'visibility']"></cdk-row>
</cdk-table>

<ng-template #uploadModal>
  <app-unit-upload (onUploadComplete)="doComplete($event)" [allUsersInput]="topFilter.allUsers"></app-unit-upload>
</ng-template>
