import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UBQ} from '../app-config';
import { ILens } from '../types/lens';
import {TopicColorService} from '../shared/topic-color.service';
import {IModifier} from '../types/modifier';
import {Observable, of} from 'rxjs';
import {materialDataSorter} from '../utils/material-data-sorter';
import { MatSort } from '@angular/material/sort';
import { LensService } from '../services-http/lens.service';

@Component({
  selector: 'app-model-distribution-graph',
  templateUrl: './model-distribution-graph.component.html',
  styleUrls: ['./model-distribution-graph.component.scss']
})
export class ModelDistributionGraphComponent implements AfterViewInit {
  public isPtfDownloading: boolean = false; 
  @Input() lens: ILens;
  @Input() modal: NgbModalRef;

  @ViewChild(MatSort, { static: true }) matSort;

  data$: Observable<IModifier[]>;
  private _devHackCount: number = 0;

  constructor(public ubq: UBQ, public lensService: LensService, public colorService: TopicColorService) {
  }

  ngAfterViewInit() {
    this.data$ = of(this.lens.modifiers).pipe(materialDataSorter(this.matSort));
  }

  topicLabel(modifier: IModifier) {
    return modifier.label || modifier.modifierModel.suggestMap.suggestedLabel;
  }

  stopWordNames() {
    return this.lens.rootStopWords.map(s => s.name);
  }

  download(): void {
    const url: string = `/core/api/lens/${this.lens.id}/ptf/download`;
    const fileName: string = `L${this.lens.id}_M${this.lens.modelId}.ptf`;
    this.isPtfDownloading = true;
    this.lensService.downloadFileToDisk(url, fileName).subscribe({
      complete: () => {
        this.isPtfDownloading = false;
      }
    });
  }

  forceWrite(lens: ILens): void {
    this._devHackCount++;
    console.log(`__devHack: increment ${this._devHackCount}`);
    if (this._devHackCount >= 10) {
      this._devHackCount = 0;
      this.lensService.DEV_TOOL_REPUBLISH(lens.id).then(() => {
        console.log('__devHack: publish finished model');
      });
    }
  }
}
