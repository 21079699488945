import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TrainingDataListComponent} from './training-data/training-data-list/training-data-list.component';
import {LensListComponent} from './lens-list/lens-list.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CreateLensComponent} from './create-lens/create-lens.component';
import {SandBoxComponent} from './sand-box/sand-box.component';
import {PlatformComponent} from './platform/platform.component';
import {LensLoaderComponent} from './lens-loader/lens-loader.component';
import {UnitListComponent} from './unit-list/unit-list.component';
import {UnitUploadComponent} from './unit-upload/unit-upload.component';

/* tslint:disable:max-line-length*/
import {ConversationalDocumentContainerComponent} from './document-views/conversational/conversational-document-container/conversational-document-container.component';
import {PiensoAuthorizationGuard} from '@pienso/auth-module';
import {TrainModelErrorComponent} from './lens-loader/train-model-error/train-model-error.component';
import {LensResolver} from './model-workspace/resolvers/lens-resolver.service';
import {StopWordListComponent} from './stop-word-list/stop-word-list.component';
import { NgModule } from '@angular/core';

const routes: Routes =
[
  {path: '', redirectTo: 'platform/home', pathMatch: 'full'},
  {
    path: 'platform', component: PlatformComponent, canActivate: [PiensoAuthorizationGuard], children: [
      {path: '', pathMatch: 'full', component: DashboardComponent},
      {path: 'sandbox', component: SandBoxComponent},
      {path: 'home', component: DashboardComponent},
      {path: 'trainingData', component: TrainingDataListComponent},
      {path: 'lenses', component: LensListComponent},
      {path: 'lenses/new', component: CreateLensComponent},
      {path: 'lenses/training/:id', component: LensLoaderComponent},
      {path: 'lenses/training/:id/error/lowdocumentcount', component: TrainModelErrorComponent, resolve: {lensData: LensResolver}, runGuardsAndResolvers: 'always'},
      {path: 'lenses/train', loadChildren: () => import('./model-workspace/model-workspace.module').then(m => m.ModelWorkspaceModule)},
      {path: 'conversation/:lensId/:topicId', component: ConversationalDocumentContainerComponent},
      {path: 'stopwords', component: StopWordListComponent},
      {path: 'dl', loadChildren: () => import('./dl-model/dl-model.module').then(m => m.DLModelModule)},
      {path: 'units', component: UnitListComponent},
      {path: 'units/upload', component: UnitUploadComponent},
      {path: 'units/analysis', loadChildren: () => import('./unit-analysis/unit-analysis.module').then(m => m.UnitAnalysisModule)},
      {path: 'data-deletion', loadChildren: () => import('./purge/purge.module').then(m => m.PurgeModule)},
      {path: '**', component: PageNotFoundComponent}],
    runGuardsAndResolvers: 'always'
  }
];

const routerOptions: ExtraOptions = { enableTracing: false, useHash: false, onSameUrlNavigation: 'reload' };

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

