<div class="row topnav-secondary">
  <div class="col-12 ps-3 pe-4">
    <div class="d-flex justify-content-between">
      <div class="">
        <button class="btn btn-primary" (click)="openModal(newListModal)">Create New Stop Word List
        </button>
      </div>
      <div class="">
        <app-top-filter
          [hasTextFilter]="true"
          [hasOrderFilter]="true"
          [selectOptions]="selectOptions">
        </app-top-filter>
      </div>
    </div>
  </div>
</div>

<cdk-table class="gridList" [dataSource]="list$"
           [ngClass]="{'datatable-load-blocker': gws.isPayloadLoading}">
  <ng-container cdkColumnDef="name">
    <cdk-header-cell *cdkHeaderCellDef> List Name</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row"> {{ row.name }} </cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="updatedAt">
    <cdk-header-cell *cdkHeaderCellDef> Last Updated</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.updatedAt | date:'medium'}}</cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="createdAt">
    <cdk-header-cell *cdkHeaderCellDef> Created At</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.createdAt | date:'medium'}}</cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="createdBy">
    <cdk-header-cell *cdkHeaderCellDef> Created By</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">{{row.createdBy }}</cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="count">
    <cdk-header-cell *cdkHeaderCellDef> No. of Words</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row"> {{row.wordCount }}</cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="actions">
    <cdk-header-cell *cdkHeaderCellDef> &nbsp;</cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">
      <div class="float-end">
        <a class="action-link" (click)="set(row); openModal(editListModal, 'modal-flush')">View List</a>
        <span class="btn btn-trasnparent btn-sm"><fa-icon [icon]="['fal', 'copy']" [size]="'lg'"  (click)="set(row); openModal(clone, 'modal-flush')"></fa-icon></span>
        <span class="btn btn-trasnparent btn-sm">
          <fa-icon *ngIf="isStopWordDownloading" [icon]="['fal', 'circle-notch']" [spin]="true"></fa-icon>
          <fa-icon *ngIf="!isStopWordDownloading" [icon]="['fal', 'arrow-to-bottom']" [size]="'lg'" (click)="downloadCSV(row.id)"></fa-icon>
        </span>
        <app-eye-toggle (click)="onHide(row)" [isEyeOpen]="row.isSoftDeleted" [eyeOpenTitle]="'Hide From List'" [eyeClosedTitle]="'Restore To List'"></app-eye-toggle>
      </div>
    </cdk-cell>
  </ng-container>

  <cdk-header-row
    *cdkHeaderRowDef="['name', 'createdAt', 'updatedAt', 'createdBy', 'count', 'actions']"></cdk-header-row>
  <cdk-row *cdkRowDef="let row; columns: ['name', 'createdAt', 'updatedAt', 'createdBy', 'count', 'actions']"></cdk-row>
</cdk-table>

<ng-template #newListModal>
  <app-stop-words-input (onUploadComplete)="closeModal()"></app-stop-words-input>
</ng-template>

<ng-template #editListModal>
  <app-stop-word-editor [dtoStopWords]="selected"></app-stop-word-editor>
</ng-template>

<ng-template #clone>
  <app-stopword-clone-modal [dto]="selected"></app-stopword-clone-modal>
</ng-template>
