<div class="row topnav-secondary">
  <div class="col-12 ps-3 pe-4">
    <div class="d-flex justify-content-between">

      <div class="action-buttons">
        <button class="btn btn-primary" (click)="openModal(newLensModal, null, 'modal-gt')">Create New Model</button>
      </div>

      <div class="">
        <app-top-filter
                        [hasTextFilter]="true"
                        [hasOrderFilter]="true"
                        [selectOptions]="selectOptions">
        </app-top-filter>
      </div>
    </div>
  </div>
</div>

<cdk-table class="gridList" [dataSource]="lenses$" [ngClass]="{'datatable-load-blocker': lenseService.isPayloadLoading}">
  <ng-container cdkColumnDef="name">
    <cdk-header-cell *cdkHeaderCellDef>Model Name</cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">{{lens.name}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="createdBy">
    <cdk-header-cell *cdkHeaderCellDef>Created By</cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">{{lens.createdBy || 'Guest'}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="createdAt">
    <cdk-header-cell *cdkHeaderCellDef>Created At</cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">{{lens.createdAt | date:'medium'}}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="stage">
    <cdk-header-cell *cdkHeaderCellDef>Model Stage</cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">{{lens.stage }}</cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="wait">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">
      <app-progress-balls *ngIf="lens.modelJobState === 'PENDING'"></app-progress-balls>
    </cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="next">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">
      <div class="refine d-inline-flex align-middle">
        <a routerLink="train/{{lens.id}}">
          <span *ngIf="lens.modelJobState == 'QUEUED'" class="action-link">Queued</span>
          <span *ngIf="lens.modelJobState == 'PENDING'" class="action-link">View Progress</span>
          <span *ngIf="lens.modelJobState == 'FINISHED'" class="action-link">View Finished Model</span>
          <span *ngIf="lens.modelJobState == 'TIMEDOUT'" class="action-link failed">View Timeout</span>
          <span *ngIf="lens.modelJobState == 'READY'" class="action-link">Train</span>
          <span *ngIf="lens.modelJobState == 'FAILED'" class="action-link failed">Failed</span>
          <span *ngIf="lens.modelJobState == 'FATAL'" class="action-link fatal">Fatal Error</span>
        </a>
      </div>
    </cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="tags">
    <cdk-header-cell *cdkHeaderCellDef>Tags</cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens"><app-model-tags [model]="lens" [modelTagsService]="lenseService"></app-model-tags></cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="overview">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">
      <div [ngClass]="{'disabled': !isExplorerActive(lens), 'img-link': isExplorerActive(lens) && !loaderMap.get(lens.id)}"
           [title]="isExplorerActive(lens) ? 'Model Summary' : 'Unavailable'" #child>
        <fa-icon *ngIf="!loaderMap.get(lens.id)" [icon]="['fal', 'info-circle']" (click)="openSummaryModal(summaryModal, lens, child)"></fa-icon>
        <fa-icon *ngIf="loaderMap.get(lens.id)" [icon]="['fal', 'circle-notch']" [spin]="true"></fa-icon>
      </div>
    </cdk-cell>
  </ng-container>
  <ng-container cdkColumnDef="visibility">
    <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
    <cdk-cell *cdkCellDef="let lens">
      <app-eye-toggle (click)="onHide(lens)" [isEyeOpen]="lens.isSoftDeleted" [eyeOpenTitle]="'Hide From List'" [eyeClosedTitle]="'Restore To List'"></app-eye-toggle>
    </cdk-cell>
  </ng-container>
  <cdk-header-row *cdkHeaderRowDef="['name', 'createdBy', 'createdAt', 'stage', 'wait', 'next', 'tags', 'overview', 'visibility']"></cdk-header-row>
  <cdk-row *cdkRowDef="let lens; columns: ['name', 'createdBy', 'createdAt', 'stage', 'wait', 'next', 'tags', 'overview', 'visibility']"></cdk-row>
</cdk-table>

<ng-template #newLensModal>
  <app-create-lens [modalRef]="modalRef" [allUsers]="topFilter.allUsers"></app-create-lens>
</ng-template>

<ng-template #summaryModal>
  <app-model-distribution-graph [lens]="selectedModel"></app-model-distribution-graph>
</ng-template>
