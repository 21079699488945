import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IStopWords} from '../../types/stop-words';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {plainToInstance} from 'class-transformer';
import {LensService} from '../../services-http/lens.service';
import {GlobalStopWordService} from '../../services-http/global-stop-word.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-stopword-clone-modal',
  templateUrl: './stopword-clone-modal.component.html',
  styleUrls: ['./stopword-clone-modal.component.scss']
})

export class StopwordCloneModalComponent implements OnInit, OnChanges {

  @Input() dto: IStopWords;

  public dupName: boolean = false;
  public clonedDTO: IStopWords;

  constructor(public modalService: NgbModal,
              public lensService: LensService,
              public gsw: GlobalStopWordService) { }

  ngOnInit() {
    console.log('ngOnInit', this.dto);

    // sever reference
    this.clonedDTO = _.cloneDeep(this.dto);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    console.log('ngOnChanges', this.dto);
  }

  isOk(): boolean {
    return true;
  }

  loading(): boolean {
    return false;
  }

  onClose(): void {
    this.gsw.clear();
    this.gsw.loadAll();
    this.modalService.dismissAll();
  }

  clone(): void {
    this.gsw.clone(this.clonedDTO, (stopword) => {
      console.log('new stopword', stopword);
      this.onClose();
    });
  }

  onNameChange(event: Event): void {
    console.log('input:', event);
  }

}
